import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      locale: 'main/locale',
    }),
  },
  watch: {
    locale() {
      this.setHeightForList()
    },
    tabActive() {
      this.setHeightForList()
    },
    isSidebar() {
      this.setHeightForList()
    },
  },
  mounted() {
    this.setHeightForList()
    window.addEventListener('resize', this.setHeightForList)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setHeightForList)
  },
  methods: {
    setHeightForList() {
      if (this.isSidebar === false) {
        return
      }

      this.$nextTick(() => {
        const list = document.getElementsByClassName('nav-management__list')[this.tabActive || 0]
        if (list) {
          const listMarginTop = +getComputedStyle(list).marginTop.replace('px', '')
          const card = document.querySelector('.nav-management__card')
          const cardPaddingBottom = +getComputedStyle(card).paddingBottom.replace('px', '')
          const footer = document.querySelector('.footer')

          const result = window.innerHeight
            - list.getBoundingClientRect().top
            - listMarginTop
            - cardPaddingBottom
            - footer.offsetHeight
          list.style.height = `${Math.floor(result)}px`
        }
      })
    },
  },
}
